//jsxhook

import {
  Column,
  FormError,
  H5,
  Label2,
  LinkVariants,
  PasswordInput,
  PrimaryButton,
  Row,
  TextInput
} from "@sixty-six-north/ui-system"
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik"
import React, { useState, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Box, Flex } from "theme-ui"
import * as Yup from "yup"
import Link from "../../components/Link"
import { usePasswordResetLink } from "../../components/Links"
import { Errors } from "../../models/Errors"
import { RecaptchaNotice } from "../../recaptcha/RecaptchaNotice"
import { createLogger } from "../../utils/createLogger"
import { useLogin } from "../LoginHooks"

interface FormData {
  email: string
  password: string
}

const logger = createLogger("LoginForm")

const validateFormData = (t: (key: string) => string) =>
  Yup.object().shape({
    email: Yup.string().email(t("validEmail")).required(t("emailRequired")),
    password: Yup.string().required(t("passwordRequired"))
  })

const LoginButton = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation()
  if (loading) {
    return (
      <PrimaryButton
        type={"submit"}
        fill
        disabled={true}
        loading={true}
        id="loginButton"
      >
        {t("login:loggingIn", { defaultValue: "Signing in..." })}
      </PrimaryButton>
    )
  } else {
    return (
      <PrimaryButton type={"submit"} fill id="loginButton">
        {t("login:login", { defaultValue: "Sign in" })}
      </PrimaryButton>
    )
  }
}

export const LoginContainer: React.FC<{
  children?: ReactNode
}> = ({ children, ...props }) => {
  return (
    <Box sx={{ variant: "layout.formBlock" }} {...props}>
      <Box sx={{ width: "100%" }}>{children}</Box>

      <Flex>
        <Box
          sx={{
            borderBottom: "1px solid",
            borderColor: "grey.3",
            marginTop: 32,
            marginBottom: 32
          }}
          {...props}
        />
      </Flex>
    </Box>
  )
}

export const LoginForm: React.FC<{
  onLogin: () => Promise<unknown>
}> = ({ onLogin, ...props }) => {
  const [loading, setLoading] = useState(false)
  const loginService = useLogin()
  const { t } = useTranslation("login")

  const onSubmit = (
    values: FormData,
    helpers: FormikHelpers<FormData>
  ): Promise<unknown> => {
    helpers.setSubmitting(true)
    setLoading(true)
    return loginService
      .login(values)
      .then(() => onLogin())
      .catch(error => {
        logger.error("Error logging in:", error)
        switch (error) {
          case Errors.CommerceToolsClientNotInitialized: {
            helpers.setErrors({ password: t("failureCommunicating") })
            break
          }
          case Errors.LoginError: {
            helpers.setErrors({ password: t("loginError") })
            break
          }
          default: {
            helpers.setErrors({ password: t("genericError") })
          }
        }
      })
      .then(() => {
        setLoading(false)
      })
  }

  return (
    <Column>
      <Box sx={{ mb: 16 }}>
        <H5>{t("login:login", { defaultValue: "Sign in" })}</H5>
      </Box>
      {/* <Box sx={{ color: "grey.1", mb: 12 }}>
        <TechnicalParagraph>
          <Trans name="checkout:manageYourAccount">
            Manage your account, orders, warranty and privacy settings.
          </Trans>
        </TechnicalParagraph>
      </Box> */}
      <Box sx={{ mb: 16 }}>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={onSubmit}
          validationSchema={validateFormData(t)}
        >
          {formik => {
            return (
              <Form>
                <Column>
                  <Box sx={{ width: "100%", mb: 16 }}>
                    <Field
                      name="email"
                      as={TextInput}
                      autoComplete={"username"}
                    >
                      {t("checkout:emailAddress", {
                        defaultValue: "Your email address"
                      })}
                    </Field>
                    <ErrorMessage name="email" component={FormError} />
                  </Box>
                  <Box sx={{ width: "100%", mb: 16 }}>
                    <Field
                      name="password"
                      as={PasswordInput}
                      autoComplete={"current-password"}
                    >
                      {t("registration:password", {
                        defaultValue: "Your password"
                      })}
                    </Field>
                    <ErrorMessage name="password" component={FormError} />
                  </Box>
                  <LoginButton loading={loading} />
                </Column>
              </Form>
            )
          }}
        </Formik>
      </Box>
      <Row>
        <Box sx={{ marginRight: 4 }}>
          <Label2>
            {t("login:forgottenPassword", {
              defaultValue: "Forgotten your password?"
            })}
          </Label2>
        </Box>
        <Box>
          <Link
            {...usePasswordResetLink()}
            sx={{ variant: `links.${LinkVariants.standard}` }}
          >
            <Label2>
              {t("login:resetHere", { defaultValue: "Reset it here" })}
            </Label2>
          </Link>
        </Box>
      </Row>
      <Flex>
        <Box>
          <RecaptchaNotice />
        </Box>
      </Flex>
    </Column>
  )
}
