import { Customer } from "@commercetools/platform-sdk"
import { TokenInfo } from "@commercetools/sdk-auth"
import * as Sentry from "@sentry/browser"
import { useCartService, useCartState } from "../cart/CartServiceHook"
import { CartState } from "../cart/CartState"
import { useCommerceToolsClient } from "../commercetools/CommerceToolsHooks"
import { CustomerState } from "../customer/CustomerState"
import { Errors } from "../models/Errors"
import { useRecaptcha } from "../recaptcha/RecaptchaHooks"
import { useSentryLogging } from "../sentry/CaptureMessage"
import { useAppDispatch } from "../State"
import { ifCheckout, ifNotCheckout } from "../utils/ifCheckout"
import { AccessTokenState } from "./AccessTokenState"
import { useAccessToken } from "./AuthHooks"

export const useLogin = () => {
  const withClient = useCommerceToolsClient()
  const cart = useCartState()
  const recaptcha = useRecaptcha()
  const dispatch = useAppDispatch()
  const cartService = useCartService()
  const { accessToken: token } = useAccessToken()
  const { captureMessage } = useSentryLogging()

  return {
    login: (credentials: {
      email: string
      password: string
    }): Promise<{ customer: Customer; token: TokenInfo }> => {
      const anonymousCartId = cart && !cart.customerId ? cart.id : undefined

      ifCheckout(() =>
        captureMessage("Logging user in during checkout", {
          level: "info"
        })
      )
      ifNotCheckout(() => captureMessage("Logging user in", { level: "info" }))

      return withClient
        .signIn(
          credentials.email,
          credentials.password,
          recaptcha("login"),
          anonymousCartId
        )
        .then(result => {
          ifCheckout(() =>
            captureMessage("Logged user in during checkout", {
              level: "info"
            })
          )
          ifNotCheckout(() =>
            captureMessage("Logged user in", { level: "info" })
          )
          if (result.cart) {
            return cartService
              .setEmailAddress(result.customer.email)
              .then(it => dispatch(CartState.actions.setCart(it)))
              .then(() => result)
          } else {
            return Promise.resolve(result)
          }
        })
        .then(result => {
          //save anonymous token to recover in case of logout
          if (token) {
            dispatch(AccessTokenState.actions.setAnonymousToken(token))
          }
          dispatch(CustomerState.actions.setCustomer(result.customer))
          return result
        })
        .catch(error => {
          if (error !== Errors.LoginError) {
            Sentry.captureMessage("User login failure", {
              extra: {
                error,
                cartId: cart?.id
              },
              tags: {
                critical: true
              },
              level: "error"
            })
          }
          throw error
        })
    }
  }
}
