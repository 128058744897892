import { ifBrowser } from "./Browser"

export const ifCheckout = (fn: () => void) => {
  ifBrowser(() => {
    if (document?.URL?.includes("/checkout")) {
      fn()
    }
  })
}

export const ifNotCheckout = (fn: () => void) => {
  ifBrowser(() => {
    if (!document?.URL?.includes("/checkout")) {
      fn()
    }
  })
}
